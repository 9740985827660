define("discourse/plugins/discourse-adplugin/discourse/components/ad-component", ["exports", "@ember/component", "@ember/object/computed", "@ember/service", "discourse-common/utils/decorators", "discourse/plugins/discourse-adplugin/discourse/helpers/slot-position"], function (_exports, _component, _computed, _service, _decorators, _slotPosition) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  var _dec, _dec2, _dec3, _obj;
  function _applyDecoratedDescriptor(target, property, decorators, descriptor, context) { var desc = {}; Object.keys(descriptor).forEach(function (key) { desc[key] = descriptor[key]; }); desc.enumerable = !!desc.enumerable; desc.configurable = !!desc.configurable; if ('value' in desc || desc.initializer) { desc.writable = true; } desc = decorators.slice().reverse().reduce(function (desc, decorator) { return decorator(target, property, desc) || desc; }, desc); if (context && desc.initializer !== void 0) { desc.value = desc.initializer ? desc.initializer.call(context) : void 0; desc.initializer = undefined; } if (desc.initializer === void 0) { Object.defineProperty(target, property, desc); desc = null; } return desc; }
  var _default = _exports.default = _component.default.extend((_dec = (0, _decorators.default)("router.currentRoute.attributes.__type", "router.currentRoute.attributes.id"), _dec2 = (0, _decorators.default)("router.currentRoute.parent.attributes.archetype"), _dec3 = (0, _decorators.default)("currentCategoryId", "topicTagsDisableAds", "topicListTag", "isPersonalMessage", "isRestrictedCategory"), (_obj = {
    router: (0, _service.inject)(),
    currentCategoryId: (0, _computed.or)("router.currentRoute.attributes.category.id", "router.currentRoute.parent.attributes.category_id"),
    currentCategorySlug: (0, _computed.or)("router.currentRoute.attributes.category.slug", "router.currentRoute.parent.attributes.category.slug"),
    // Server needs to compute this in case hidden tags are being used.
    topicTagsDisableAds: (0, _computed.alias)("router.currentRoute.parent.attributes.tags_disable_ads"),
    isRestrictedCategory: (0, _computed.or)("router.currentRoute.attributes.category.read_restricted", "router.currentRoute.parent.attributes.category.read_restricted"),
    topicListTag(type, tag) {
      if (type === "tag" && tag) {
        return tag;
      }
    },
    isPersonalMessage(topicType) {
      return topicType === "private_message";
    },
    showToGroups() {
      if (!this.currentUser) {
        return true;
      }
      return this.currentUser.show_to_groups;
    },
    showOnCurrentPage(categoryId, topicTagsDisableAds, topicListTag, isPersonalMessage, isRestrictedCategory) {
      return !topicTagsDisableAds && (!categoryId || !this.siteSettings.no_ads_for_categories || !this.siteSettings.no_ads_for_categories.split("|").includes(categoryId.toString())) && (!topicListTag || !this.siteSettings.no_ads_for_tags || !this.siteSettings.no_ads_for_tags.split("|").includes(topicListTag)) && (!isPersonalMessage || !this.siteSettings.no_ads_for_personal_messages) && (!isRestrictedCategory || !this.siteSettings.no_ads_for_restricted_categories);
    },
    isNthPost(n) {
      return (0, _slotPosition.isNthPost)(n, this.get("postNumber"));
    },
    isNthTopicListItem(n) {
      return (0, _slotPosition.isNthTopicListItem)(n, this.get("indexNumber"));
    }
  }, (_applyDecoratedDescriptor(_obj, "topicListTag", [_dec], Object.getOwnPropertyDescriptor(_obj, "topicListTag"), _obj), _applyDecoratedDescriptor(_obj, "isPersonalMessage", [_dec2], Object.getOwnPropertyDescriptor(_obj, "isPersonalMessage"), _obj), _applyDecoratedDescriptor(_obj, "showToGroups", [_decorators.default], Object.getOwnPropertyDescriptor(_obj, "showToGroups"), _obj), _applyDecoratedDescriptor(_obj, "showOnCurrentPage", [_dec3], Object.getOwnPropertyDescriptor(_obj, "showOnCurrentPage"), _obj)), _obj)));
});